<template>
<div>
 
	<VueTableDynamic 
	   @cell-click='click' 
	   @cell-contextmenu="ctxMenu" 
	   @changed="getChangedSet" 
	   @loadAll="loadAll"
	   @search="changeSearch"
	   :params=params>
	 </VueTableDynamic>
</div>
</template>

<script>
export default 
{
name: "GTableJSON",
props: {
    json: Array,
    columns: Array,
    height: { type: Number, default: 550 }
  },
data() {
      return {
         params: { data: [],
            header: 'row',
            height: 550,
            pagination: true,
            pageSize: 16,
            style: { },
            enableSearch: true,
            dataCount: 0,
            dataCountAll: 0,
            sort: [],
            pageSizes: [5, 10, 15, 16, 18, 20, 25, 50, 100],
            rowHeight: 18,
            columnWidth: [
					
                ],
            border: false },
      }
  },
  methods: {
    changeSearch(search) {  },
    loadAll() { this.$emit("loadAll") },
  	click(rowIndex, columnIndex, data) { this.$emit("cell-click", rowIndex, columnIndex, data) },
  	ctxMenu(rowIndex, columnIndex, data) { this.$emit("cell-contextmenu",rowIndex, columnIndex, data)},
  	getChangedSet(array) { this.$emit("changed", array)},
  	process(columns, data)
  	{
  		if ( columns && columns.length &&  data && data.length )
  		{
  			let arr = new Array();
  			let row = new Array();
  			
  			this.params.sort = [];
  			for ( let c in columns )
  			{
  				this.params.sort.push( row.length );
  				row.push( columns[c] );
  			}
  			arr.push( row);
  			for ( let r in data )
  			{
  			    row = new Array();
  				for ( let cx in columns )
  				{
  				    let c = columns[cx];
  				    try
  				    {
	  				    if ( data[r][c] )
	  				    {
	  						row.push( data[r][c] );
	  					}
	  					else
	  					{
	  						row.push( "");
	  					}
	  				} catch (e) {
	  				   row.push( "");
	  				}
  				}
  				arr.push( row);
  			}
  			this.params.dataCountAll = arr.length-1;
  			this.params.dataCount = arr.length-1;
  			this.params.data = arr;
  			
  		}
  	}
  },
  watch: {
  	json: function() {
  		//this.process( this.json);
  	}
  }
}
</script>
